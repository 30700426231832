import React, {useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import startcase from 'lodash.startcase'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'
import OAuthError from './OAuthError'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonStyle: {margin: 5},
  buttonLabel: {paddingLeft: 3}
}

function getIconByProviderType (type) {
  switch (type) {
    case 'gitlab':
      return 'gitlab'
    case 'github':
      return 'github'
    default:
      return null
  }
}

export default function SocialLogin ({appId, onClick}) {
  const {loading, responseBody = [], status} = useFetch('GET', `/apps/${appId}/providers/`)

  const buttons = useMemo(
    () => (Array.isArray(responseBody) ? responseBody : []).map(provider => (
      <Button
        icon={getIconByProviderType(provider.type)}
        key={provider.id}
        onClick={() => onClick({appId, providerId: provider.id})}
        style={styles.buttonStyle}
      >
        <span style={styles.buttonLabel}>
          {provider.label || <FormattedMessage id='loginwith.button' values={{providerType: startcase(provider.id)}} />}
        </span>
      </Button>
    )),
    [appId, onClick, responseBody])

  if (loading) {
    return <Spinner />
  }

  if (!Array.isArray(responseBody) || responseBody.length === 0 || status !== 200) {
    return <OAuthError error='providers retrieval failed' errorDescription='' />
  }

  return (
    <div style={styles.container}>
      <h1><FormattedMessage defaultMessage='Login' id='login.message' /></h1>
      <div style={styles.buttons}>
        {buttons}
      </div>
    </div>
  )
}
SocialLogin.propTypes = {
  appId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
