import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import {OAuthTokenConsumer} from '../components/OAuthTokenConsumer'
import OAuthError from '../components/OAuthError'
import {verifyAndClearOauthState} from '../lib/oauthState'
import {headersContentTypeFormUrlEncoded, oauthTokenEndpoint} from '../components/consts'

const buildError = (
  code,
  description
) => {
  return {
    code,
    description
  }
}

const validateRequest = (query) => {
  if (query.error || query.error_description) {
    return buildError(query.error, query.error_description)
  }

  if (!query.code || !query.state) {
    return buildError(
      'Bad parameters',
      'Bad redirect parameters. Please login again.'
    )
  }

  if (!verifyAndClearOauthState(query.state)) {
    return buildError(
      'Bad state',
      'The provided state is not valid. Please login again.'
    )
  }
}

const createRequest = (query, sendInBody) => {
  const stringifiedRequestData = qs.stringify(query, {addQueryPrefix: false})
  const tokenQuery = sendInBody ? '' : '?' + stringifiedRequestData

  const endpoint = `${oauthTokenEndpoint}${tokenQuery}`

  const headers = sendInBody ? headersContentTypeFormUrlEncoded : {}
  const tokenBody = sendInBody ? stringifiedRequestData : undefined

  return {
    endpoint,
    headers,
    tokenBody
  }
}

function Callback ({location, onError, sendCodeStateInBody}) {
  const query = qs.parse(location.search, {ignoreQueryPrefix: true})
  const error = validateRequest(query)

  if (error) {
    return (
      <OAuthError
        error={error.code}
        errorDescription={error.description}
        loginRetryUri='/'
      />
    )
  }

  const requestInfo = createRequest(query, sendCodeStateInBody)

  return (
    <OAuthTokenConsumer
      endpoint={requestInfo.endpoint}
      headers={requestInfo.headers}
      onError={onError}
      tokenBody={requestInfo.tokenBody}
    />
  )
}

Callback.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  onError: PropTypes.func.isRequired,
  /**
   * If `true`, code and state parameters are sent as a form encoded body. Otherwise, they are set in the query string.
   */
  sendCodeStateInBody: PropTypes.bool
}

export default Callback
