import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'
import SocialLogin from '../components/SocialLogin'
import {setClientKeyEndpoint} from '../components/consts'
import {buildInitalizerPath, isWithinIFrame, openPopup} from '../utils'
import {LoginPending} from '../components/LoginPending'

function Login ({history, location}) {
  const {search} = location
  const query = qs.parse(search, {ignoreQueryPrefix: true})
  const {appId, providerId, redirect, external} = query

  const openInPopup = Boolean(external || isWithinIFrame())

  const [loginWindow, setLoginWindow] = useState(null)
  useEffect(() => {
    const onMessage = (event) => {
      if (event.origin !== window.origin) {
        return
      }

      // ensure that the message has been sent from the window previously opened
      if (loginWindow && event.source === loginWindow) {
        loginWindow.close()
        window.open(event.data?.replaceLocation ?? redirect, '_self')
      }
    }

    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [loginWindow, redirect])

  const navigate = useCallback((targetPath) => history.push(targetPath), [history])
  const popup = useCallback((targetPath) => setLoginWindow(openPopup(targetPath)), [])

  const onSocialLoginButtonClick = useCallback(({appId, providerId}) => {
    const targetPath = buildInitalizerPath({appId, providerId, redirect, external})
    openInPopup ?
      popup(targetPath) :
      navigate(targetPath)
  }, [external, navigate, openInPopup, popup, redirect])

  const {loading} = useFetch('GET', setClientKeyEndpoint)
  if (loading) {
    return <Spinner />
  }

  if (loginWindow !== null) {
    return <LoginPending />
  }

  if (openInPopup || (appId && !providerId)) {
    return (
      <SocialLogin
        appId={appId}
        onClick={onSocialLoginButtonClick}
        redirectUrl={redirect}
      />
    )
  }

  navigate(buildInitalizerPath(query))
  return null
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
}

export default Login
