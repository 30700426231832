import React from 'react'
import {FormattedMessage} from 'react-intl'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  }
}

export function LoginPending () {
  return (
    <div style={styles.container}>
      <h1>
        <FormattedMessage defaultMessage='Authentication in progress...' id='login.wait.message' />
      </h1>
    </div>
  )
}
