import React from 'react'
import PropTypes from 'prop-types'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'

export const OAuthTokenConsumer = ({endpoint, headers = {}, tokenBody, onError}) => {
  const {
    status,
    error,
    redirectLocation,
    loading
  } = useFetch('POST', endpoint, tokenBody, headers)

  if (loading) {
    return <Spinner />
  }

  if (error || status !== 200) {
    onError(status, error?.message || 'Unexpected error while logging in. Please logout and try again.')
    return <span />
  }

  const replaceLocation = `${window.location.origin}${redirectLocation}`

  if (window.opener && !window.opener.closed) {
    window.opener.postMessage({replaceLocation}, window.opener.location.origin)
  } else {
    if (redirectLocation && redirectLocation !== replaceLocation) {
      window.location.replace(replaceLocation)
    }
  }

  return null
}

OAuthTokenConsumer.propTypes = {
  endpoint: PropTypes.string.isRequired,
  headers: PropTypes.object,
  onError: PropTypes.func.isRequired,
  tokenBody: PropTypes.string
}
