import PropTypes from 'prop-types'
import qs from 'qs'
import {useCallback, useMemo} from 'react'
import {generateAndSaveOauthState} from '../lib/oauthState'
import {redirectToAuthorize} from '../utils'

export const useAuthorizeInitializer = ({appId, providerId, redirect}) => {
  const appQuery = useMemo(() => ({
    appId,
    providerId,
    redirect
  }), [appId, providerId, redirect])
  const gotoAuthorize = useCallback(() => {
    const state = generateAndSaveOauthState()
    const query = qs.stringify({state, ...appQuery}, {addQueryPrefix: true})
    redirectToAuthorize(query)
  }, [appQuery])

  return {gotoAuthorize}
}

export default function AuthorizeInitializer ({location}) {
  const {search} = location
  const {appId, providerId, redirect} = qs.parse(search, {ignoreQueryPrefix: true})

  const {gotoAuthorize} = useAuthorizeInitializer({appId, providerId, redirect})
  gotoAuthorize()
  return null
}
AuthorizeInitializer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
}
