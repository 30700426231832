export const STATE_SESSION_STORAGE_KEY = 'mia:login-site-oauth-state'

const generateOauthState = () => {
  return crypto.randomUUID()
}

const saveOauthState = (state) => {
  sessionStorage.setItem(STATE_SESSION_STORAGE_KEY, state)
}

export const generateAndSaveOauthState = () => {
  const state = generateOauthState()
  saveOauthState(state)
  return state
}

const verifyOauthState = (state) => {
  return state === sessionStorage.getItem(STATE_SESSION_STORAGE_KEY)
}

const clearOauthState = () => {
  sessionStorage.removeItem(STATE_SESSION_STORAGE_KEY)
}

export const verifyAndClearOauthState = (state) => {
  const result = verifyOauthState(state)
  clearOauthState()
  return result
}
