import qs from 'qs'

import {appRootPath, authorizeEndpoint, authorizeInitializerEndpoint} from './components/consts'
import {omit} from 'lodash'

export const redirectToAuthorize = (encodedQueryString) => {
  window.location.replace(`${window.location.origin}${authorizeEndpoint}${encodedQueryString ?? ''}`)
}

export const buildInitalizerPath = (query) =>
  `${authorizeInitializerEndpoint}${qs.stringify(omit(query, ['external']), {addQueryPrefix: true})}`

export const openPopup = (location) => {
  return window.open(
    `${window.location.origin}${appRootPath}${location}`,
    'authenticationPopup',
    'popup,width=720,height=640,left=360,top=360'
  )
}

export const isWithinIFrame = () => window.location !== window.parent.location
