import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {notification} from 'antd'

import Callback from './containers/Callback'
import Login from './containers/Login'
import LoginInitializer from './containers/AuthorizeInitializer'

function App () {
  const intl = useIntl()

  const [error, setError] = useState(null)
  const onError = (status, message) => {
    setError({
      statusCode: status,
      message
    })
  }

  useEffect(() => {
    if (error?.statusCode >= 500) {
      notification.warn({
        message: intl.formatMessage({id: 'error'}),
        description: intl.formatMessage({id: 'callback.error'}, error)
      })
    }
  }, [error, intl])

  return (
    <Switch>
      {!error && <Route exact path='/callback' render={(props) => <Callback {...props} onError={onError} />} />}
      {!error && <Route exact path='/oauth/callback' render={(props) => <Callback {...props} onError={onError} sendCodeStateInBody />} />}
      <Route component={Login} exact path='/' />
      <Route component={LoginInitializer} exact path='/init' />
      <Redirect to='/' />
    </Switch>
  )
}

export default App
